<template>
  <div 
    class='absolute w-screen' 
    style='left: calc(50% - 50vw); z-index: -1;'
    :style='propStyle'></div>
</template>

<script>
export default {
  name: 'ContentBackgroundCover',
  props: [
    'height',
    'isFullHeight',
    'backgroundColor',
    'backgroundImage',
    'customCss',
    'topOffset',
  ],
  computed: {
    heightComputedStyle () {
      return (this.height) ? `${this.height}px` : '100%' // 28rem
    },
    heightStyle () {
      return (this.isFullHeight) ? 'calc(100% + 3.5rem);' : this.heightComputedStyle
    },
    backgroundColorStyle () {
      return (this.backgroundColor) ? this.backgroundColor : '#F3F4F6'
    },
    backgroundImageStyle () {
      return (this.backgroundImage) ? `background-image: url(${this.backgroundImage});background-size:cover;` : ''
    },
    topOffsetStyle () {
      return (this.topOffset) ? this.topOffset : 0
    },
    propStyle () {
      return `height:${this.heightStyle}; top: ${this.topOffsetStyle}; ${this.backgroundImageStyle} background-color:${this.backgroundColorStyle};${this.customCss}`
    },
  }
}

</script>
